import React, { useContext, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Divider from '@material-ui/core/Divider'
import { Badge } from '@material-ui/core'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'

import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined'
import CampaignIcon from '@material-ui/icons/Send'


import SendOutlined from '@material-ui/icons/SendOutlined'

//import ReportOutlined from "@bit/mui-org.material-ui-icons.report-outlined";

import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined'
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'

import { i18n } from '../translate/i18n'
import { WhatsAppsContext } from '../context/WhatsApp/WhatsAppsContext'
import { AuthContext } from '../context/Auth/AuthContext'
import { Can } from '../components/Can'
//import openSocket from 'socket.io-client'
import { socket } from '../services/socket'
import api from '../services/api'



function ListItemLink(props) {
  const { icon, primary, to, className } = props

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  )

  return (
    <li>
      <ListItem button component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  )
}

const MainListItems = (props) => {
  const { setDrawerOpen } = props
  const { whatsApps } = useContext(WhatsAppsContext)
  const { user } = useContext(AuthContext)
  const [connectionWarning, setConnectionWarning] = useState(false)
  const [settings, setSettings] = useState([])


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === 'qrcode' ||
            whats.status === 'PAIRING' ||
            whats.status === 'DISCONNECTED' ||
            whats.status === 'TIMEOUT' ||
            whats.status === 'OPENING'
          )
        })
        if (offlineWhats.length > 0) {
          setConnectionWarning(true)
        } else {
          setConnectionWarning(false)
        }
      }
    }, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [whatsApps])


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchSession = async () => {
        try {
          try {
            const { data } = await api.get('/settings')

            setSettings(data.settings)
          } catch (err) {
            // toastError(err)
          }
        } catch (err) {
          // toastError(err)
        }
      }
      fetchSession()
    }, 500)
    return () => clearTimeout(delayDebounceFn)

  }, [])

  const getSettingValue = (key) => {

    return settings?.find((s) => s?.key === key)?.value

    // const { value } = settings.find((s) => s?.key === key)
    // return value
  }

  useEffect(() => {
    //const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    const onSettingsMainListItem = (data) => {
      if (data.action === 'update') {
        setSettings((prevState) => {
          const aux = [...prevState]
          const settingIndex = aux.findIndex((s) => s.key === data.setting.key)
          aux[settingIndex].value = data.setting.value
          return aux
        })
      }
    }

    socket.on('settings', onSettingsMainListItem)

    return () => {
      socket.off('settings', onSettingsMainListItem);
    }
  }, [])

  return (
    //Solicitado pelo Adriano: Click no LinkItem e fechar o menu!
    <div onClick={() => setDrawerOpen(false)}>
      <ListItemLink
        to="/tickets"
        primary={i18n.t('mainDrawer.listItems.tickets')}
        icon={<WhatsAppIcon />}
      />

      <ListItemLink
        to="/contacts"
        primary={i18n.t('mainDrawer.listItems.contacts')}
        icon={<ContactPhoneOutlinedIcon />}
      />
      <ListItemLink
        to="/schedulesReminder"
        primary={i18n.t('mainDrawer.listItems.schedules')}
        icon={<SendOutlined />}
      />
      <ListItemLink
        to="/quickAnswers"
        primary={i18n.t('mainDrawer.listItems.quickAnswers')}
        icon={<QuestionAnswerOutlinedIcon />}
      />


      <Can
        role={user.profile}
        perform="dashboard-view:show"
        yes={() => (
          <>
            <Divider />
            <ListSubheader inset>{i18n.t("mainDrawer.listItems.administration")}</ListSubheader>

            <ListItemLink
              to="/statusChatEnd"
              primary={"Status"}
              icon={<RateReviewOutlinedIcon />}
            />

            <ListItemLink
              to="/position"
              primary={"Cargo"}
              icon={<PlaylistAddIcon />}
            />

            <ListItemLink
              to="/"
              primary="Dashboard"
              icon={<DashboardOutlinedIcon />}
            />
            <ListItemLink
              to="/report"
              primary={i18n.t("mainDrawer.listItems.reports")}
              icon={<ReportOutlinedIcon />}
            />
          </>
        )}
      />





      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<PeopleAltOutlinedIcon />}
            />
            <ListItemLink
              to="/queues"
              primary={i18n.t('mainDrawer.listItems.queues')}
              icon={<AccountTreeOutlinedIcon />}
            />

            <ListItemLink
              to="/connections"
              primary={i18n.t('mainDrawer.listItems.connections')}
              icon={
                <Badge badgeContent={connectionWarning ? '!' : 0} color="error">
                  <SyncAltIcon />
                </Badge>
              }
            />

            {/* <ListItemLink
              to="/"
              primary="Dashboard"
              icon={<DashboardOutlinedIcon />}
            /> */}

            {/* <ListItemLink
              to="/report"
              primary="Relatório"
              icon={<ReportOutlinedIcon />}
            /> */}

            {
              (getSettingValue('hasCampaign') === 'enabled' || user.profile === 'master') && (
                <ListItemLink
                  to="/campaign"
                  primary={i18n.t("mainDrawer.listItems.campaign") || 'Campaign'}
                  icon={<CampaignIcon />}
                />
              )
            }


            <Can
              role={user.profile}
              perform="settings-view:show"
              yes={() => (
                <ListItemLink
                  to="/settings"
                  primary={i18n.t('mainDrawer.listItems.settings')}
                  icon={<SettingsOutlinedIcon />}
                />
              )}
            />
          </>
        )}
      />
    </div>
  )
}

export default MainListItems
