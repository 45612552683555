import { useState, useEffect, useReducer } from "react";
//import openSocket from "socket.io-client";
import { socket } from "../../services/socket";

import toastError from "../../errors/toastError";


import api from "../../services/api";

const reducer = (state, action) => {
	if (action.type === "LOAD_WHATSAPPS") {
		const whatsApps = action.payload;

		return [...whatsApps];
	}

	if (action.type === "UPDATE_WHATSAPPS") {
		const whatsApp = action.payload;
		const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

		if (whatsAppIndex !== -1) {
			state[whatsAppIndex] = whatsApp;
			return [...state];
		} else {
			return [whatsApp, ...state];
		}
	}

	if (action.type === "UPDATE_SESSION") {
		const whatsApp = action.payload;
		const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

		if (whatsAppIndex !== -1) {

			if ('disabled' in whatsApp) {
				state[whatsAppIndex].disabled = whatsApp.disabled
			}
			else {
				state[whatsAppIndex].status = whatsApp.status;
				state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
				state[whatsAppIndex].qrcode = whatsApp.qrcode;
				state[whatsAppIndex].retries = whatsApp.retries;
			}


			return [...state];
		} else {
			return [...state];
		}
	}

	// if(action.type === "UPDATE_SESSION_RESTORE"){

	// 	const whatsApp = action.payload;

	// 	console.log('------> whatsApp: ', whatsApp)

	// 	const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

	// 	if (whatsAppIndex !== -1) {
	// 		console.log('kkkkkkkkkkkkkkkkkkkkkkkkkk: ',whatsAppIndex)

	// 		return [...state];
	// 	}
	// }


	// if (action.type === "UPDATE_DISK_SPACE_MONIT") {
	// 	const whatsApp = action.payload;
	// 	const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

	// 	if (whatsAppIndex !== -1) { 
	// 		state[whatsAppIndex].sessionSize = whatsApp.sessionSize;
	// 		return [...state];
	// 	} else {
	// 		return [whatsApp, ...state];
	// 	}
	// }


	if (action.type === "UPDATE_WHATSAPPS_SESSION_MONIT") {
		const whatsApp = action.payload;
		const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

		if (whatsAppIndex !== -1) {
			state[whatsAppIndex].sessionSize = whatsApp.sessionSize;
			return [...state];
		} else {
			return [whatsApp, ...state];
		}
	}

	if (action.type === "DELETE_WHATSAPPS") {
		const whatsAppId = action.payload;

		const whatsAppIndex = state.findIndex(s => s.id === whatsAppId);
		if (whatsAppIndex !== -1) {
			state.splice(whatsAppIndex, 1);
		}
		return [...state];
	}


	if (action.type === "RESET") {
		return [];
	}
};

const useWhatsApps = () => {
	const [whatsApps, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(true);



	useEffect(() => {
		setLoading(true);
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/whatsapp/");
				dispatch({ type: "LOAD_WHATSAPPS", payload: data });
				setLoading(false);
			} catch (err) {
				setLoading(false);
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		//const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("whatsapp", data => {
			if (data.action === "update") {
				dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
			}
		});

		socket.on("whatsapp", data => {
			if (data.action === "delete") {
				dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
			}
		});

		socket.on("whatsappSession", data => {
			if (data.action === "update") {
				dispatch({ type: "UPDATE_SESSION", payload: data.session });
			}
			else if (data.action === "update_restore") {
				dispatch({ type: "UPDATE_SESSION_RESTORE", payload: data.session });
			}
		});

		socket.on("whatsappSessionMonit", data => {
			if (data.action === "update") {
				dispatch({ type: "UPDATE_WHATSAPPS_SESSION_MONIT", payload: data.whatsappSessionSize });
			}
		});



		return () => {
			socket.removeAllListeners('whatsapp');
			socket.removeAllListeners('whatsappSession');
			socket.removeAllListeners('whatsappSessionMonit');
		};
	}, []);

	return { whatsApps, loading };
};

export default useWhatsApps;
