import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { AuthProvider } from '../context/Auth/AuthContext'
import { WhatsAppsProvider } from '../context/WhatsApp/WhatsAppsContext'
import LoggedInLayout from '../layout'
import Route from './Route'
import BackdropLoading from "../components/BackdropLoading";

const Dashboard = lazy(() => import('../pages/Dashboard/'));
const Report = lazy(() => import('../pages/Report/'));
const SchedulesReminder = lazy(() => import('../pages/SchedulesReminder/'));
const Tickets = lazy(() => import('../pages/Tickets/'));
const Signup = lazy(() => import('../pages/Signup/'));
const Login = lazy(() => import('../pages/Login/'));
const Connections = lazy(() => import('../pages/Connections/'));
const Campaign = lazy(() => import('../pages/Campaign/'));
const Settings = lazy(() => import('../pages/Settings/'));
const Users = lazy(() => import('../pages/Users/'));
const Contacts = lazy(() => import('../pages/Contacts/'));
const QuickAnswers = lazy(() => import('../pages/QuickAnswers/'));
const StatusChatEnd = lazy(() => import('../pages/StatusChatEnd/'));
const Position = lazy(() => import('../pages/Position/'));
const Queues = lazy(() => import('../pages/Queues/'));

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Suspense fallback={<BackdropLoading />}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <WhatsAppsProvider>
              <LoggedInLayout>
                <Route exact path="/" component={Dashboard} isPrivate />
                <Route
                  exact
                  path="/tickets/:ticketId?"
                  component={Tickets}
                  isPrivate
                />
                <Route
                  exact
                  path="/connections"
                  component={Connections}
                  isPrivate
                />
                <Route exact path="/report" component={Report} isPrivate />
                <Route exact path="/contacts" component={Contacts} isPrivate />
                <Route
                  exact
                  path="/schedulesReminder"
                  component={SchedulesReminder}
                  isPrivate
                />
                <Route exact path="/users" component={Users} isPrivate />
                <Route
                  exact
                  path="/quickAnswers"
                  component={QuickAnswers}
                  isPrivate
                />
                <Route
                  exact
                  path="/statusChatEnd"
                  component={StatusChatEnd}
                  isPrivate
                />
                <Route exact path="/position" component={Position} isPrivate />
                <Route exact path="/Settings" component={Settings} isPrivate />
                <Route exact path="/Queues" component={Queues} isPrivate />
                <Route exact path="/campaign" component={Campaign} isPrivate />
              </LoggedInLayout>
            </WhatsAppsProvider>
          </Switch>
        </Suspense>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default Routes
